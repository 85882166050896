<script lang="ts">
	import { getContext } from "svelte";
	import { storeConfig } from "../../stores/storeConfig";
	import { upsyContext } from "../../stores/upsyContext";
	import { GlobalContextKeyEnum } from "../../types/context";
	import { IProduct } from "../../types/product";
	import { IConfigObject } from "../../types/sdk";
	import variantType, { variantEnum } from "../../types/variant";
	import { postProductClickEvent } from "../../utils/event";
	import { getLocaleForPriceFormating } from "../../utils/locale";
	import ProductCardLabel from "./ProductCardLabel.svelte";

	const NINETY_DAYS_IN_MS = 7776000000;

	export let previewMode = false;
	export let variant: variantType = variantEnum.MINIFIED;
	export let product: IProduct;

	let {
		productId,
		price,
		salePrice = 0,
		productName,
		permalink,
		imageUrl,
		createdAt = 0,
		saleStartTs = 0,
		saleEndTs = 4294967295000
	} = product;
	//Use current page base url as fallback base url
	const productPermalink = new URL(permalink, window.location.origin);

	let upsyConfig: IConfigObject;
	$: upsyConfig = $storeConfig;

	export let isNewProduct = false;
	export let isSaleProduct = false;

	if (!isNewProduct && !isSaleProduct) {
		const now = Date.now();
		const limitingDay =
			now - +($storeConfig.newArrivalTimeLimit || NINETY_DAYS_IN_MS);
		// This logic is borrowed from upsy-api (backend) for determining new/sale product
		isNewProduct = +createdAt > limitingDay;
		isSaleProduct = +salePrice > 0 && +saleStartTs < now && +saleEndTs > now;
	}
	// Use Finish(fi) as fallback language for product price formating
	const lang = $storeConfig.language || "fi";
	// Use EURO(EUR) as fallback currency for product price formating
	const currencyCode = $storeConfig.currencyCode || "EUR";

	const minimumFractionDigits =
		"number" == typeof $storeConfig.minimumFractionDigits &&
		$storeConfig.minimumFractionDigits >= 0
			? $storeConfig.minimumFractionDigits
			: 2;
	const locale = getLocaleForPriceFormating(currencyCode, lang);
	const currencyFormat = new Intl.NumberFormat(locale, {
		style: "currency",
		currency: currencyCode,
		minimumFractionDigits: minimumFractionDigits
	});

	const environment = getContext<string>(GlobalContextKeyEnum.ENVIRONMENT);
	function productClickHandler() {
		try {
			!previewMode &&
				void postProductClickEvent(
					upsyConfig,
					environment,
					$upsyContext,
					productId
				);
		} catch (error) {
			console.log(error);
		}
	}
	let classZ = "";
	export { classZ as class };
</script>

<a
	href={productPermalink.toString()}
	on:click={productClickHandler}
	class={`${
		variant === variantEnum.MINIFIED
			? "u-w-[120px] u-h-[160px] sm:u-w-[160px] sm:u-h-[168px]"
			: "u-w-[185px] u-h-[189.55px]"
	} u-block u-no-underline u-box-border u-cursor-pointer u-shadow-upsy u-rounded-[10px] u-bg-card-gray  u-grow-0 u-shrink-0 u-overflow-hidden ${classZ}`}>
	<div
		class="u-relative u-h-[51.2%] u-flex u-justify-center u-items-center u-overflow-hidden u-bg-white">
		<div
			style={imageUrl
				? `background-image:url('${imageUrl
						.replace(/'/g, "%27")
						.replace(/"/g, "%22")}')`
				: ``}
			class="u-h-[80px] u-w-[80px] u-overflow-hidden u-bg-center u-bg-origin-border u-bg-white u-bg-contain u-bg-no-repeat" />

		{#if isNewProduct || isSaleProduct}
			<div class="u-flex u-absolute u-top-2 u-right-2 u-gap-1">
				{#if isSaleProduct}
					<ProductCardLabel
						{variant}
						label="SALE"
						class="u-bg-sale-product-label" />
				{/if}
				{#if isNewProduct}
					<ProductCardLabel
						{variant}
						label="NEW"
						class="u-bg-new-product-label" />
				{/if}
			</div>
		{/if}
	</div>
	<div class="u-h-[27.5%]">
		<p
			class={`${
				variant === variantEnum.MINIFIED
					? "u-text-xs sm:u-text-[13px] u-leading-4"
					: "u-text-[13px] u-leading-5"
			} u-font-inter u-font-medium u-line-clamp-2 u-text-black-secondary u-px-2 u-pt-2`}>
			{productName}
		</p>
	</div>
	<hr
		class="u-block u-w-full u-m-0 u-p-0 u-border-0 u-border-t-[1px] u-border-dash-gray u-border-dashed" />
	<div
		class="u-w-full u-h-[20%] u-bg-card-gray u-flex u-justify-between u-flex-wrap u-px-2 u-items-center">
		<p
			class={`${
				variant === variantEnum.MINIFIED
					? "u-text-sm sm:u-text-[15px] u-leading-3"
					: "u-text-[15px] u-leading-4"
			} u-mr-[1px] u-text-black-full u-font-semibold u-tracking-normal`}>
			{price && salePrice && isSaleProduct
				? currencyFormat.format(salePrice)
				: currencyFormat.format(price)}
		</p>
		{#if price && salePrice && isSaleProduct}
			<p
				class={`${
					variant === variantEnum.MINIFIED
						? "u-text-[11px] sm:u-text-[13px] u-leading-3"
						: "u-text-[13px] u-leading-3 sm:u-leading-4"
				} u-line-through u-text-justify u-font-medium u-text-accent-secondary  u-tracking-normal`}>
				{currencyFormat.format(price)}
			</p>
		{/if}
	</div>
</a>
