export function upsyGenerateId(length: number) {
	let s = "";
	do {
		s += Math.random().toString(36).substring(2);
	} while (s.length < length);
	s = s.substring(0, length);
	return s;
}

export function generateUpsySession(customerId: string, length = 16) {
	return customerId + "-sid-" + upsyGenerateId(length);
}
