import { upsyContext } from "../stores/upsyContext";
import { UpsyContextEnum } from "../types/context";
import { IConfigObject } from "../types/sdk";
import { upsyGetCookie, upsySetCookie } from "./cookie";
import { pageTypeEnum } from "./pageType";
import { getCurrentUrlWithoutQueryParams } from "./url";
import { ActivePromotionEnum } from "../types/sdk";

export function getUpsyRecommendationContext(pageType: string, config: IConfigObject) {
	const { isDailyDealsDisabled, isNewArrivalTabDisabled, activePromotion } = config;

	// const woocomMessage = document.querySelector(".woocommerce-message");
	// const wooComNoticeWrapper = document.querySelector(".woocommerce-notices-wrapper");
	// const alertSuccess = document.querySelector(".alert-success");
	// const message =
	// 	(wooComNoticeWrapper && wooComNoticeWrapper.textContent) ||
	// 	(woocomMessage && woocomMessage.textContent) ||
	// 	(alertSuccess && alertSuccess.textContent);

	// if (
	// 	message &&
	// 	(message.toLowerCase().indexOf("has been added") !== -1 ||
	// 		message.toLowerCase().indexOf("ostoskoriin") !== -1)
	// ) {
	// 	return UpsyContextEnum.CROSS_SELL;
	// }
	const currentUrlWithoutQueryParams = getCurrentUrlWithoutQueryParams();
	const isCrossSellContextCookie = upsyGetCookie(currentUrlWithoutQueryParams);
	upsySetCookie(currentUrlWithoutQueryParams, "", 0);

	switch (pageType) {
		case pageTypeEnum.FRONT:
		case pageTypeEnum.GENERIC:
		case pageTypeEnum.THANKYOU:
			return UpsyContextEnum.CATEGORY;
		/**
		 * When on category page:
		 * If active promotion value is "DAILY_DEALS" and daily deals tab is not disabled then upsy context will also be set to "DAILY_DEALS"
		 * If active promotion value is "NEW_ARRIVAL" and new arrival tab is not disabled then upsy context will also be set to "NEW_ARRIVAL"
		 * If There have no value for active promotion and daily deals tab is not disabled then "DAILY_DEALS" will be upsy context.(default behaviour)
		 * All the other senario "CATEGORY" will be the context.(fallback senario)
		 */
		case pageTypeEnum.CATEGORY: {
			if (
				activePromotion == ActivePromotionEnum.DAILY_DEALS &&
				!isDailyDealsDisabled
			) {
				return UpsyContextEnum.DAILY_DEALS;
			} else if (
				activePromotion == ActivePromotionEnum.NEW_ARRIVAL &&
				!isNewArrivalTabDisabled
			) {
				return UpsyContextEnum.NEW_ARRIVAL;
			} else {
				return UpsyContextEnum.CATEGORY;
			}
		}
		case pageTypeEnum.PRODUCT: {
			if (isCrossSellContextCookie && document.referrer) {
				return UpsyContextEnum.CROSS_SELL;
			}
			return UpsyContextEnum.UP_SELL;
		}
		case pageTypeEnum.CHECKOUT:
		case pageTypeEnum.CART:
			return UpsyContextEnum.FAQ;

		default:
			return UpsyContextEnum.CATEGORY;
	}
}
