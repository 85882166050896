/**
 * It returns the value of a cookie with the name you pass in
 * @param {string} cname - The name of the cookie you want to get.
 * @returns The value of the cookie with the name cname.
 */
export function upsyGetCookie(cname: string) {
	const name = cname + "=";
	const ca = document.cookie.split(";");
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}

/**
 * It sets a cookie with the name cname, the value cvalue, and the expiration date expdays
 * @param {string} cname - The name of the cookie.
 * @param {string} cvalue - the value of the cookie
 * @param {number} expdays - The number of days you want the cookie to last.
 */
export function upsySetCookie(cname: string, cvalue: string, expdays: number): void {
	const d = new Date();
	d.setTime(d.getTime() + expdays * 24 * 60 * 60 * 1000);
	const expires = "expires=" + d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
