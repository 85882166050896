import en from "./language/english";
import fi from "./language/finish";
import sv from "./language/swedish";
import de from "./language/german";
import nl from "./language/dutch";

export type Itranslation = {
	[name: string]: {
		[name: string]: string;
	};
};

const translation: Itranslation = { en, fi, sv, de, nl };

export default translation;
