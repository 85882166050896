import { get } from "svelte/store";
import { init } from "./sdk/init";
import { preview } from "./sdk/preview";
import { isSdkLoaded } from "./stores/sdkLoaded";

window.upsy_sdk = {
	init,
	preview,
	isLoaded: () => get(isSdkLoaded)
};
