export function registerEventToAll<T>(
	event: string,
	targets: Element[] | NodeList | HTMLAllCollection,
	handler: (e: Event) => T
) {
	const listeners: { element: Node; handler: (e: Event) => T }[] = [];
	if (targets && targets.length) {
		Array.from(targets).forEach(element => {
			element.addEventListener(event, handler);
			listeners.push({ element, handler });
		});
	}
	// remove event listener to prevent memory leak
	return function removeAllEvents() {
		listeners.forEach(({ element, handler }) => {
			element.removeEventListener(event, handler);
		});
	};
}
