import { getCategoryId } from "./category";
import { upsyGetCookie, upsySetCookie } from "./cookie";
import { getPageType } from "./pageType";
import { getProductId, getProductNameFromContext } from "./product";
import { generateUpsySession } from "./session";
import {
	CLOSE_BY_USER_COOKIE_KEY,
	SESSION_COOKIE_KEY,
	STORAGE_UPSY_CART_KEY,
	STORAGE_UPSY_PRODUCT_UPSELL_KEY,
	UPSY_PURCHASE_COMPLETED_COOKIE_KEY
} from "../constants";
import { getShoppingCart, ICartProduct, recheckShoppingCart } from "./cart";
import {
	getInitAnalyticsEventURL,
	getAnalyticsEventURL,
	getFeedbackURL
} from "./getUpsyUrls";
import { getEnvironmentEndpoints } from "./getEnvironmentEndpoints";
import { getStorage, setStorage } from "./storage";
import { IConfigObject } from "../types/sdk";
import { isInitClicked } from "../stores/trackEvent";
import { get as getStore } from "svelte/store";
import { UpsyContextEnum } from "../types/context";

export interface IInitEventPayload {
	session: string;
	chatId: string;
	pageviews: number;
	date: number | string;
	environment: "prod"; //Must be prod or Upsy won't load
	sessionId: string;
	customerId: string;
	timestamp: number | string;
	context: {
		categoryId: string | null;
		productId: string | null;
		productName: string | null;
		pageType: string;
		referrer: string;
		currentUrl: string;
		shoppingCart: ICartProduct[];
	};
}

export enum EventEnum {
	CLICK = "click",
	DISPLAY_SCREEN = "displayScreen",
	PRODUCT_CLICK = "productClick",
	FAQ_QUESTION = "faqQuestion",
	CATEGORY_CLICK = "categoryClick",
	PURCHASE = "purchase"
}

export enum EventTargetEnum {
	INITIAL_CLICK = "initialClick",
	RELEVANT_PRODUCT_POPUP = "relevantProductsPopup",
	RELEVANT_PRODUCTS = "relevantProducts",
	UP_SELL = "UP_SELL",
	CROSS_SELL = "CROSS_SELL",
	RELATED_SELL = "RELATED_SELL",
	CATEGORY = "CATEGORY",
	NEW_ARRIVAL = "NEW_ARRIVAL",
	DAILY_DEALS = "DAILY_DEALS",
	FAQ = "faq",
	PURCHASE = "purchase"
}

/* This is a mapping of the upsy context to the event target. For example, if the upsy context is
up-sell, then the event target will be up-sell. */
const upsyContextToEventTargetMapper: { [key: string]: string } = {
	[UpsyContextEnum.CATEGORY]: EventTargetEnum.CATEGORY,
	[UpsyContextEnum.CROSS_SELL]: EventTargetEnum.CROSS_SELL,
	[UpsyContextEnum.UP_SELL]: EventTargetEnum.UP_SELL,
	[UpsyContextEnum.RELATED_SELL]: EventTargetEnum.RELATED_SELL,
	[UpsyContextEnum.DAILY_DEALS]: EventTargetEnum.DAILY_DEALS,
	[UpsyContextEnum.NEW_ARRIVAL]: EventTargetEnum.NEW_ARRIVAL,
	[UpsyContextEnum.FAQ]: EventTargetEnum.FAQ
};

export type IEvent = `${EventEnum}`;
export interface IEventPayloadParams {
	event: IEvent;
	config: IConfigObject;
	eventTarget?: string | null;
	environment: string;
	products?: unknown;
}

export interface IEventPayload {
	event: string;
	customerId: string;
	chatId: string;
	sessionId: string;
	environment: "prod";
	timestamp: number | string;
	params: {
		pageType: string;
		eventTarget?: string | null;
		products?: unknown;
	};
}

export interface IStorageUpsyProductUpsell {
	productId: unknown;
	context: string | null;
}

export async function postInitEvent(config: IConfigObject, environment: string) {
	const { tenantId: customerId, isSlowShoppingCartCustomer } = config;
	// Check if cookie exists. If exists use session ID.
	let session = upsyGetCookie(SESSION_COOKIE_KEY);
	if (!session) {
		session = generateUpsySession(customerId);
		// 2 hours expiries time in days, (1/24)*2 = 0.08333 days
		const twoHoursInDays = 0.08333;
		upsySetCookie(SESSION_COOKIE_KEY, session, twoHoursInDays);
	}

	const chatId = session;

	const timestamp = Date.now().toString();

	let shoppingCart = getShoppingCart();

	if (shoppingCart.length === 0) {
		if (isSlowShoppingCartCustomer == true) {
			shoppingCart = await recheckShoppingCart();
		}
	}

	const payload: IInitEventPayload = {
		session,
		chatId,
		customerId,
		pageviews: 1,
		date: timestamp,
		environment: "prod", //Must be prod or Upsy won't load
		sessionId: session,
		timestamp: timestamp,
		context: {
			categoryId: getCategoryId(),
			productId: getProductId(),
			productName: getProductNameFromContext(),
			pageType: getPageType(config),
			referrer: document.referrer,
			currentUrl: document.location.href,
			shoppingCart
		}
	};
	const { analyticsEventBaseURL, analyticsEventKey } =
		getEnvironmentEndpoints(environment);
	const analyticsInitEventURL = getInitAnalyticsEventURL(
		analyticsEventBaseURL,
		customerId,
		session,
		analyticsEventKey
	);

	try {
		const response = await fetch(analyticsInitEventURL, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(payload)
		});

		if (!response.ok) {
			return Promise.reject(response);
		}
		return true;
	} catch (err) {
		return Promise.reject(err);
	}
}

// Others event post interface/function
export async function postEvent(eventPayloadParams: IEventPayloadParams) {
	const { event, eventTarget, config, environment, products } = eventPayloadParams;
	const { tenantId: customerId } = config;

	let session = upsyGetCookie(SESSION_COOKIE_KEY);
	if (!session) {
		session = generateUpsySession(customerId);
		// 2 hours expiries time in days, (1/24)*2 = 0.08333 days
		const twoHoursInDays = 0.08333;
		upsySetCookie(SESSION_COOKIE_KEY, session, twoHoursInDays);
	}

	const chatId = session;
	const timestamp = Date.now().toString();
	const payload: IEventPayload = {
		event,
		customerId,
		chatId,
		sessionId: session,
		environment: "prod",
		timestamp: timestamp,
		params: {
			pageType: getPageType(config)
		}
	};

	if (eventTarget) {
		payload.params["eventTarget"] = eventTarget;
	}

	if (products) {
		payload.params["products"] = products;
	}
	switch (event) {
		case EventEnum.CLICK: {
			// half day expiries time, 1/2 = 0.5 day
			const halfDay = 0.5;
			upsySetCookie(CLOSE_BY_USER_COOKIE_KEY, "0", halfDay);
			break;
		}
		case EventEnum.PRODUCT_CLICK: {
			const storageArray = getStorage(
				STORAGE_UPSY_PRODUCT_UPSELL_KEY
			) as IStorageUpsyProductUpsell[];
			const storageArrayPush = {
				productId: products,
				context: eventTarget ? eventTarget : null
			};

			Array.isArray(storageArray) && storageArray.push(storageArrayPush);
			setStorage(STORAGE_UPSY_PRODUCT_UPSELL_KEY, storageArray);
			break;
		}
		case EventEnum.PURCHASE: {
			if (!payload.params["products"]) {
				const cart = getStorage(STORAGE_UPSY_CART_KEY) as ICartProduct[];
				payload.params["products"] = cart;
			}
			const noProducts =
				!payload.params["products"] ||
				!(payload.params["products"] as ICartProduct[]).length;
			if (noProducts) {
				return;
			}
			setStorage(STORAGE_UPSY_CART_KEY, []);
			const twoHoursInDays = 0.08333;
			upsySetCookie(UPSY_PURCHASE_COMPLETED_COOKIE_KEY, "1", twoHoursInDays);
			break;
		}
	}

	const { analyticsEventBaseURL, analyticsEventKey } =
		getEnvironmentEndpoints(environment);
	const analyticsEventURL = getAnalyticsEventURL(
		analyticsEventBaseURL,
		customerId,
		session,
		analyticsEventKey
	);

	try {
		const response = await fetch(analyticsEventURL, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(payload)
		});

		if (!response.ok) {
			return Promise.reject(response);
		}
		return response;
	} catch (err) {
		return Promise.reject(err);
	}
}

export async function postClickEvent(config: IConfigObject, environment: string) {
	if (!getStore(isInitClicked)) {
		const eventPayloadParams: IEventPayloadParams = {
			config,
			environment,
			event: EventEnum.CLICK,
			eventTarget: EventTargetEnum.INITIAL_CLICK
		};
		// Update isInitClicked value to true for send init click event only once
		isInitClicked.update(() => true);
		return postEvent(eventPayloadParams);
	}
	return;
}

/**
 * It posts an event to the Upsy server
 * @param {IConfigObject} config - The configuration object that you pass to the Upsy widget.
 * @param {string} environment - string - The environment in which the event is being sent.
 * @param {string} upsyContext - This is the context of the widget. For example, if you're using
 * the up-sell widget, the context will be up-sell.
 * @param {unknown} [products] - This is an array of products that are being displayed on the screen.
 * @returns An array of promises.
 */
export async function postDisplayScreenEvent(
	config: IConfigObject,
	environment: string,
	upsyContext: string | null,
	products?: unknown
) {
	let relevantProductEventTarget: string | null = null;

	const upsyContextForRelevantProductEventTarget: string[] = [
		UpsyContextEnum.CROSS_SELL,
		UpsyContextEnum.UP_SELL,
		UpsyContextEnum.RELATED_SELL
	];

	if (upsyContext && upsyContextForRelevantProductEventTarget.includes(upsyContext)) {
		relevantProductEventTarget = EventTargetEnum.RELEVANT_PRODUCT_POPUP;
	}
	/* This is to get the event target from the upsy context. For example, if the upsy context is
	up-sell, then the event target will be up-sell. */
	let eventTarget = upsyContext;
	if (upsyContext && upsyContextToEventTargetMapper[upsyContext]) {
		eventTarget = upsyContextToEventTargetMapper[upsyContext];
	}

	const eventPayloadParams: IEventPayloadParams = {
		config,
		environment,
		event: EventEnum.DISPLAY_SCREEN,
		eventTarget,
		products
	};

	if (relevantProductEventTarget) {
		return Promise.all([
			postEvent({ ...eventPayloadParams, eventTarget: relevantProductEventTarget }),
			postEvent(eventPayloadParams)
		]);
	}
	return Promise.all([postEvent(eventPayloadParams)]);
}

export async function postFaqQuestionEvent(
	config: IConfigObject,
	environment: string,
	eventTarget: string | null
) {
	const eventPayloadParams: IEventPayloadParams = {
		config,
		environment,
		event: EventEnum.FAQ_QUESTION,
		eventTarget
	};
	return postEvent(eventPayloadParams);
}

export async function postProductClickEvent(
	config: IConfigObject,
	environment: string,
	eventTarget: string | null,
	products: unknown
) {
	const eventPayloadParams: IEventPayloadParams = {
		config,
		environment,
		event: EventEnum.PRODUCT_CLICK,
		eventTarget,
		products
	};
	return postEvent(eventPayloadParams);
}
export async function postCategoryClickEvent(
	config: IConfigObject,
	environment: string,
	eventTarget: string | null,
	products: unknown
) {
	const eventPayloadParams: IEventPayloadParams = {
		config,
		environment,
		event: EventEnum.CATEGORY_CLICK,
		eventTarget,
		products
	};
	return postEvent(eventPayloadParams);
}

export async function postPurchaseEvent(
	config: IConfigObject,
	environment: string,
	products?: ICartProduct[]
) {
	const eventPayloadParams: IEventPayloadParams = {
		config,
		environment,
		event: EventEnum.PURCHASE,
		eventTarget: EventTargetEnum.PURCHASE,
		products
	};
	return postEvent(eventPayloadParams);
}

export const postFeedback = (
	config: IConfigObject,
	environment: string,
	feedback: { like: boolean; ts: string; detailedMessage?: string }
) => {
	const { feedbackApiURL } = getEnvironmentEndpoints(environment);
	const { tenantId } = config;
	const feedbackUrl = getFeedbackURL(feedbackApiURL, tenantId);
	const sessionId = upsyGetCookie(SESSION_COOKIE_KEY);

	const body = {
		...feedback,
		created: new Date(feedback.ts).getTime(),
		createdString: feedback.ts,
		roomId: config.tenantId,
		id: sessionId,
		sessionId
	};

	void fetch(feedbackUrl, {
		method: "POST",
		mode: "no-cors",
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify(body)
	});
};
