import { ICategory } from "./category";
import { init } from "../sdk/init";
import { preview } from "../sdk/preview";
import {
	ICrisp,
	IFB,
	IFC_Widget,
	IjQuery,
	ILC_API,
	ILiveChatWidget,
	IOlarkAPI,
	ISmartsuppAPI,
	ITawkAPI,
	ITidioAPI,
	IZopim,
	IZendesk,
	ISmooch,
	IZendeskClassic
} from "./externalLibraries";
import { ICartProduct } from "../utils/cart";

interface UpsySDK {
	init: typeof init;
	preview: typeof preview;
	isLoaded: () => boolean;
	loadUpsyBranch?: (upsyBranch: string) => Promise<void>;
	getTenantId?: () => string;
	getOrder?: () => ICartProduct[];
	getPageType?: () => string;
	isThankYouPage?: () => boolean;
	getUpsySession?: () => string | undefined;
	getCookie?: (name: string) => string;
	setCookie?: (name: string, value: string, expDays: number) => void;
}

type Shopify = {
	checkout?: {
		line_items: {
			price: string;
			quantity: string;
			product_id: string;
			title: string;
		}[];
		currency: string;
	};
};

declare global {
	interface Window {
		upsy_sdk: UpsySDK;
		jQuery?: IjQuery;

		// Chat SDKs
		$zopim?: IZopim;
		FB?: IFB;
		fcWidget?: IFC_Widget;
		_initChat?: () => void; // Part of FC
		Tawk_API?: ITawkAPI;
		smartsupp?: ISmartsuppAPI;
		LC_API?: ILC_API;
		LiveChatWidget?: ILiveChatWidget;
		$crisp?: ICrisp;
		tidioChatApi?: ITidioAPI;
		olark?: IOlarkAPI;
		zE: IZendesk | IZendeskClassic;
		Shopify?: Shopify;
		Smooch: ISmooch;
	}
}

export interface IConfigObjectResponse {
	data: {
		config: {
			upsyConfig: IConfigObject;
		};
	};
}

export type IChatType =
	| "zopim"
	| "shopify"
	| "tawk"
	| "lc" //live chat
	| "fc" //fresh chat
	| "fb" //facebook chat
	| "crisp"
	| "tidio"
	| "smartsupp"
	| "olark"
	| "zendesk"
	| "sc" // zendesk sunshine conversation;
	| "zendeskClassic"; // New version of zendesk

export interface IChatHandler {
	initializeChat: () => void;
	openChat: (closeWidget: () => void) => void;
}

export interface ITrigger {
	isPurchase?: boolean;
	type?: string;
	value?: string;
}
export interface Iflow {
	trigger: ITrigger;
}
export interface IFAQLocalization {
	question?: string;
	answer?: string;
}
export interface IFAQ {
	answer: string;
	question: string;
	type: string;
	isPinded?: boolean;
	locale?: Record<string, IFAQLocalization>;
}

export interface IGreetingsUpperText {
	upperText?: string;
}
export interface IGreetingsTextInBubble {
	textInBubble?: string;
}

export interface IFaqGreetingsFaq extends IGreetingsTextInBubble, IGreetingsUpperText {}

export interface IGreetings {
	category?: {
		upperText?: string;
		locale?: Record<string, IGreetingsUpperText>;
	};
	dailyDeal?: {
		upperText?: string;
		locale?: Record<string, IGreetingsUpperText>;
	};
	faq?: {
		textInBubble?: string;
		upperText?: string;
		locale?: Record<string, IFaqGreetingsFaq>;
	};
	feedback?: {
		negativeFeedback?: string;
		positiveFeedback?: string;
		textInBubble?: string;
		thankYouText?: string;
	};
	homePage?: {
		textInBubble?: string;
		locale?: Record<string, IGreetingsTextInBubble>;
	};
	newArrival?: {
		upperText?: string;
		locale?: Record<string, IGreetingsUpperText>;
	};
	upSell?: {
		upperText?: string;
		locale?: Record<string, IGreetingsUpperText>;
	};
	crossSell?: {
		upperText?: string;
		locale?: Record<string, IGreetingsUpperText>;
	};
}

export enum ActivePromotionEnum {
	NEW_ARRIVAL = "NEW_ARRIVAL",
	DAILY_DEALS = "DAILY_DEALS"
}

export interface IConfigObject {
	minimizedMode?: boolean;
	smallScreenWidthThreshold?: number;
	chatOptions?: {
		enabled: boolean;
		type: IChatType;
		language: string;
	};
	addToCartSelector: string;
	addToCartOptions: {
		addIsValidQuery: string;
	};
	productOptions?: {
		customFilter: string;
	};
	avatar: string;
	buttonColor: string;
	cartUrl: string;
	customQnA: IFAQ[];
	disableAutoPopup: number;
	engine: string;
	environment: string;
	flows: Iflow[];
	language: string;
	noPurchaseEvents?: boolean;
	currencyCode?: string;
	minimumFractionDigits?: number;
	tenantId: string;
	customerCategories: ICategory[];
	testMode: string;
	zIndex?: number;
	widgetToLeft?: boolean;
	liftMinifiedWidget?: string;
	liftExpandedWidget?: string;
	liftUpsy?: string;
	liftUpsyDesktop?: string;
	accentColorLight?: string;
	isSlowShoppingCartCustomer?: boolean;
	newArrivalTimeLimit?: number;
	newArrivalSortingOrder?: string;
	isNewArrivalTabDisabled?: boolean;
	isDailyDealsDisabled?: boolean;
	activePromotion?: `${ActivePromotionEnum}` | "";
	customCssForSite?: string;
	greetings?: IGreetings;
}
