import {
	FallBackProductResponse,
	NewArrivalProductResponse,
	DailyDealsProductResponse
} from "../types/productResponse";
import { IProductResponse } from "../types/productResponse";
import { IUpsyContext, UpsyContextEnum } from "../types/context";
import { getEnvironmentEndpoints } from "./getEnvironmentEndpoints";
import {
	getCrossSellURL,
	getDailyDealsURL,
	getFallbackURL,
	getNewArrivalURL,
	getRelatedURL,
	getUpSellURL
} from "./getUpsyUrls";
import { getProductId } from "./product";
import { IProduct } from "../types/product";
import { getCategoryId, getFormattedCategory } from "./category";
import { IConfigObject } from "../types/sdk";

const NINETY_DAYS_IN_MS = 7776000000;

export async function getUpsyContexualProducts(
	config: IConfigObject,
	environment: string,
	upsyContext: IUpsyContext
): Promise<IProduct[]> {
	const { tenantId: storeId, productOptions: { customFilter } = {} } = config;
	const { apiURL: baseUrl } = getEnvironmentEndpoints(environment);
	let products: IProduct[] = [];

	switch (upsyContext) {
		case UpsyContextEnum.UP_SELL:
		case UpsyContextEnum.CROSS_SELL: {
			products = await getUpsellOrCrossSellProducts(
				baseUrl,
				storeId,
				upsyContext,
				customFilter
			);
			break;
		}
		case UpsyContextEnum.NEW_ARRIVAL: {
			products = await getNewArrivalProducts(baseUrl, storeId, {
				timeLimit: config.newArrivalTimeLimit,
				sortingOrder: config.newArrivalSortingOrder
			});
			break;
		}
		case UpsyContextEnum.DAILY_DEALS: {
			products = await getDailyDealsProducts(baseUrl, storeId, customFilter);
			break;
		}
		default: {
			products = [];
		}
	}

	return products;
}

export async function getUpsellOrCrossSellProducts(
	baseUrl: string,
	storeId: string,
	upsyContext: Extract<IUpsyContext, "UP_SELL" | "CROSS_SELL">,
	customFilter?: string
): Promise<IProduct[]> {
	const productId = getProductId();
	if (!productId) {
		return [];
	}
	let url = getUpSellURL(baseUrl, storeId, productId, customFilter);
	if (upsyContext === "CROSS_SELL") {
		url = getCrossSellURL(baseUrl, storeId, productId, customFilter);
	}
	try {
		// Fetch up-sell/cross-sell products
		const response = await fetch(url);
		const data = (await response.json()) as unknown as IProductResponse;
		if (data && Array.isArray(data.results) && data.results.length > 0) {
			return sortByPrice(data.results);
		}
		if (upsyContext === UpsyContextEnum.UP_SELL) {
			// If related products is empty then try to fetch fallback products
			const fallbackProducts = await fetchFallBackProducts(
				baseUrl,
				storeId,
				customFilter
			);
			if (
				fallbackProducts &&
				Array.isArray(fallbackProducts) &&
				fallbackProducts.length > 0
			) {
				return fallbackProducts;
			}
		}

		return [];
	} catch (e) {
		if (!(e instanceof FallbackError) && upsyContext === UpsyContextEnum.UP_SELL) {
			return await fetchFallBackProducts(baseUrl, storeId, customFilter);
		}
		return [];
	}
}

export async function fetchRelatedProducts(baseUrl: string, storeId: string) {
	const productId = getProductId();
	if (!productId) {
		return [];
	}
	const relatedUrl = getRelatedURL(baseUrl, storeId, productId);
	const response = await fetch(relatedUrl);
	const data = (await response.json()) as unknown as IProductResponse;
	return data && Array.isArray(data.results) ? data.results : [];
}

class FallbackError extends Error {
	constructor(message?: string) {
		super(message);
		this.name = "FallbackError";
	}
}
export async function fetchFallBackProducts(
	baseUrl: string,
	storeId: string,
	customFilter?: string
): Promise<IProduct[]> {
	const productId = getProductId();
	if (!productId) {
		return [];
	}
	const fallbackUrl = getFallbackURL(baseUrl, storeId, productId, customFilter);
	try {
		const response = await fetch(fallbackUrl);
		const data = (await response.json()) as unknown as FallBackProductResponse;
		const fallbackProducts =
			data && Array.isArray(data.results) && Array.isArray(data.results[0].hits)
				? data.results[0].hits
				: [];
		return sortByPrice(fallbackProducts);
	} catch (e) {
		throw new FallbackError();
	}
}

export interface NewArrivalApiOptions {
	page?: number;
	timeLimit?: number;
	hitsPerPage?: number;
	sortingOrder?: string;
}

export async function getNewArrivalProducts(
	baseUrl: string,
	storeId: string,
	options: NewArrivalApiOptions = {}
): Promise<IProduct[]> {
	let url = getNewArrivalURL(baseUrl, storeId);
	const categoryName = getFormattedCategory(getCategoryId());

	const { page, hitsPerPage, timeLimit, sortingOrder } = {
		page: options.page || 0,
		hitsPerPage: options.hitsPerPage || 30,
		timeLimit: options.timeLimit || NINETY_DAYS_IN_MS,
		sortingOrder: (options.sortingOrder || "").trim()
	};

	url += `/?timeLimit=${timeLimit}&page=${page}&hitsPerPage=${hitsPerPage}`;
	if (categoryName) {
		url += `&categories=${encodeURIComponent(categoryName)}`;
	}

	if (sortingOrder) url += `&sortingOrder=${encodeURIComponent(sortingOrder)}`;

	const response = await fetch(url);
	const data = (await response.json()) as unknown as NewArrivalProductResponse;
	return data.hits.length ? data.hits : [];
}

export async function getDailyDealsProducts(
	baseUrl: string,
	storeId: string,
	customFilter?: string,
	page = 0,
	hitsPerPage = 30
): Promise<IProduct[]> {
	const categoryName = getFormattedCategory(getCategoryId()) as string | undefined;
	const url = getDailyDealsURL(
		baseUrl,
		storeId,
		categoryName,
		customFilter,
		page,
		hitsPerPage
	);
	const response = await fetch(url);
	const data = (await response.json()) as unknown as DailyDealsProductResponse;
	return data.hits.length ? data.hits : [];
}

const getMinPrice = (product: IProduct) => {
	if (!product.salePrice) return parseFloat(`${product.price}`);
	return Math.min(parseFloat(`${product.salePrice}`), parseFloat(`${product.price}`));
};
const sortByPrice = (products: IProduct[], descendingOrder = false) => {
	return products.sort((a, b) =>
		descendingOrder
			? getMinPrice(b) - getMinPrice(a)
			: getMinPrice(a) - getMinPrice(b)
	);
};
