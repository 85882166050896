import {
	UPSY_API_BASE_URL_STAGING,
	UPSY_API_BASE_URL_STAGING_EU_WEST_1,
	UPSY_STATIC_BASE_URL_STAGING,
	UPSY_API_BASE_URL_LOCAL,
	UPSY_API_BASE_URL_LOCAL_EU_WEST_1,
	UPSY_STATIC_BASE_URL_LOCAL,
	UPSY_API_BASE_URL,
	UPSY_API_BASE_URL_EU_WEST_1,
	UPSY_STATIC_BASE_URL,
	UPSY_ANALYTICS_BASE_URL,
	UPSY_ANALYTICS_BASE_URL_LOCAL,
	UPSY_ANALYTICS_BASE_URL_STAGING,
	UPSY_STATIC_BASE_URL_FEATURE,
	UPSY_ANALYTICS_KEY,
	UPSY_ANALYTICS_KEY_STAGING
} from "../constants";

const isFeatureBranch = (environment: string) => {
	return /upsy-*|ocs-*/.test(environment);
};

const getFeatureBranchStaticBaseUrl = (branch: string) => {
	return UPSY_STATIC_BASE_URL_FEATURE.replace("{}", branch);
};

export const getEnvironmentEndpoints = (environment: string) => {
	if (environment === "staging") {
		return {
			apiURL: UPSY_API_BASE_URL_STAGING,
			feedbackApiURL: UPSY_API_BASE_URL_STAGING_EU_WEST_1,
			staticResourceURL: UPSY_STATIC_BASE_URL_STAGING,
			analyticsEventBaseURL: UPSY_ANALYTICS_BASE_URL_STAGING,
			analyticsEventKey: UPSY_ANALYTICS_KEY_STAGING
		};
	} else if (environment === "local") {
		return {
			apiURL: UPSY_API_BASE_URL_LOCAL,
			feedbackApiURL: UPSY_API_BASE_URL_LOCAL_EU_WEST_1,
			staticResourceURL: UPSY_STATIC_BASE_URL_LOCAL,
			analyticsEventBaseURL: UPSY_ANALYTICS_BASE_URL_LOCAL,
			analyticsEventKey: UPSY_ANALYTICS_KEY_STAGING
		};
		// upsy-650 branch is for nordic buddies specific branch
	} else if (
		["upsy-358", "upsy-425", "upsy-487", "upsy-650", "upsy-658"].includes(environment)
	) {
		// Hard coded treatment for a specific test branch
		return {
			apiURL: UPSY_API_BASE_URL,
			feedbackApiURL: UPSY_API_BASE_URL_EU_WEST_1,
			staticResourceURL: getFeatureBranchStaticBaseUrl(environment),
			analyticsEventBaseURL: UPSY_ANALYTICS_BASE_URL,
			analyticsEventKey: UPSY_ANALYTICS_KEY
		};
	} else if (isFeatureBranch(environment)) {
		return {
			apiURL: UPSY_API_BASE_URL_STAGING,
			feedbackApiURL: UPSY_API_BASE_URL_STAGING_EU_WEST_1,
			staticResourceURL: getFeatureBranchStaticBaseUrl(environment),
			analyticsEventBaseURL: UPSY_ANALYTICS_BASE_URL_STAGING,
			analyticsEventKey: UPSY_ANALYTICS_KEY_STAGING
		};
	}
	if (environment !== "production") {
		console.error(
			`Upsy environment was ${environment}, but no matching environment was found - using 'production' instead`
		);
	}

	return {
		apiURL: UPSY_API_BASE_URL,
		feedbackApiURL: UPSY_API_BASE_URL_EU_WEST_1,
		staticResourceURL: UPSY_STATIC_BASE_URL,
		analyticsEventBaseURL: UPSY_ANALYTICS_BASE_URL,
		analyticsEventKey: UPSY_ANALYTICS_KEY
	};
};
