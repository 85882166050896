import { activeWidgetScreenEnum } from "../types/activeWidgetScreen";
import { IConfigObject } from "../types/sdk";
import { getFlowFromUrl } from "./getFlowFromUrl";

export enum pageTypeEnum {
	FRONT = "front",
	CATEGORY = "category",
	PRODUCT = "product",
	CART = "cart",
	CHECKOUT = "checkout",
	THANKYOU = "thank_you",
	GENERIC = "generic"
}

function isMatchURL(urls: string[]) {
	const currentURL = window.location.href;
	return urls.some(url => currentURL.includes(url));
}

export function getPageTypeByUpsySelector(): string | void {
	const pageTypeContainer = document.querySelector(".upseller_page_type");
	if (pageTypeContainer && pageTypeContainer.textContent) {
		return pageTypeContainer.textContent.split("_")[0];
	}
}
export function getPageType(config?: IConfigObject): string {
	const categoryPageUrls = ["category", "tuote-osasto", "group"];
	const thankYouPageUrls = ["order-received", "order_thank_you", "thank_you"];
	const checkoutPageUrls = ["order_summary", "checkout"];
	const cartPageUrls = ["ostoskori", "cart"];

	let pageType: string = pageTypeEnum.GENERIC;

	const pageTypeByUpsySelector = getPageTypeByUpsySelector();

	if (pageTypeByUpsySelector) {
		pageType = pageTypeByUpsySelector;
	} else if (isMatchURL(categoryPageUrls)) {
		pageType = pageTypeEnum.CATEGORY;
	} else if (isMatchURL(cartPageUrls)) {
		pageType = pageTypeEnum.CART;
	} else if (isMatchURL(thankYouPageUrls)) {
		pageType = pageTypeEnum.THANKYOU;
	} else if (isMatchURL(checkoutPageUrls)) {
		pageType = pageTypeEnum.CHECKOUT;
	}

	if (config) {
		const flow = getFlowFromUrl(config);
		if (flow?.trigger.isPurchase) {
			pageType = pageTypeEnum.THANKYOU;
		}
	}
	return pageType.trim();
}

export function getWidgetScreenByPageType(activePageType: string, minimizedMode = false) {
	switch (activePageType) {
		case pageTypeEnum.FRONT: {
			/*
			 * Show GREETINGSM widget screen(eventually sidebar cta will be shown) if upsy
			 * is in minimize mode rather showing greeting message popup
			 */
			if (minimizedMode) return activeWidgetScreenEnum.GREETINGSM;
			return activeWidgetScreenEnum.GREETINGLG;
		}
		case pageTypeEnum.GENERIC:
			return activeWidgetScreenEnum.GREETINGLG;
		case pageTypeEnum.PRODUCT:
			return activeWidgetScreenEnum.GREETINGSM;
		case pageTypeEnum.CATEGORY:
			return activeWidgetScreenEnum.GREETINGSM;
		case pageTypeEnum.THANKYOU:
			return activeWidgetScreenEnum.FEEDBACK;
		case pageTypeEnum.CHECKOUT:
		case pageTypeEnum.CART:
			return activeWidgetScreenEnum.ANYQUESTION;
		default:
			return activeWidgetScreenEnum.GREETINGLG;
	}
}
