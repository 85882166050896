export const joinURL = (...args: string[]) => {
	return args
		.join("/")
		.replace(/[/]+/g, "/")
		.replace(/^(.+):\//, "$1://")
		.replace(/^file:/, "file:/")
		.replace(/\/(\?|&|#[^!])/g, "$1")
		.replace(/\?/g, "&")
		.replace("&", "?");
};

export const getCurrentUrlWithoutQueryParams = () => {
	return window.location.href.split("?")[0].replace(/^\/+|\/+$/g, "");
};
