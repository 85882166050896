export function getProductId() {
	let productId: string | null = "";
	let products = document.querySelectorAll(".upseller_product .product_id");

	if (products.length) {
		for (let i = 0; i < products.length; i++) {
			const last = i + 1;

			if (last === products.length) {
				productId = products[i].textContent;
			}
		}
	} else {
		products = document.querySelectorAll(".product_id");

		if (products.length) {
			for (let i = 0; i < products.length; i++) {
				const last = i + 1;

				if (last === products.length) {
					productId = products[i].textContent;
				}
			}
		} else {
			const priceElement = document.querySelector(".price-final_price");
			if (priceElement) {
				const id = priceElement.getAttribute("data-product-id");
				if (id !== null) {
					productId = id;
				}
			}
		}
	}
	return (productId || "").trim();
}

export function getProductNameFromContext() {
	let productName: string | null = "";
	const upsyProductName = document.querySelector(".upseller_product .name");
	if (upsyProductName) {
		productName = upsyProductName.textContent;
	} else {
		const simplePid = document.querySelector(".name");
		if (simplePid) {
			productName = simplePid.textContent;
		}
	}
	return (productName || "").trim();
}
